<template>
  <div>
    <template>
      <Search
        :filters="filters"
        @loadOffers="searchOffers()"
      />
      <v-flex
        xs12
      >
        <v-carousel
          height="376"
          cycle
          style="box-shadow: none;"
          hide-delimiters
        >
          <v-carousel-item
            v-for="(banner) in banners"
            :key="banner.uuid"
          >
            <a
              :href="banner.url"
              target="_blank"
            >
              <v-img
                v-if="$vuetify.breakpoint.smAndDown"
                :src="banner.small_picture"
                :alt="banner.title"
                height="100%"
              />
              <v-img
                v-else
                :src="banner.big_picture"
                :alt="banner.title"
                height="100%"
              />
            </a>
          </v-carousel-item>

          <v-carousel-item
            v-if="false"
          >
            <a
              href="https://plataforma.mandomedio.com"
              target="_blank"
            >
              <v-img
                v-if="$vuetify.breakpoint.smAndDown"
                :src="require('@/assets/banner-portal-empleo-movil3.png')"
                height="100%"
              />
              <v-img
                v-else
                :src="require('@/assets/banner-portal-empleo3.png')"
                height="100%"
              />
            </a>
          </v-carousel-item>

          <v-dialog
            v-model="signup_modal"
            max-width="432"
            persistent
          >
            <CandidateSignup
              @signupDone="finishSignup"
              @closeModal="signup_modal = false"
            />
          </v-dialog>
          <v-dialog
            v-if="youtube_modal"
            v-model="youtube_modal"
            width="600"
            height="350"
          >
            <v-card>
              <v-toolbar
                dark
                color="primary"
              >
                <v-toolbar-title>Video Explicativo</v-toolbar-title>
              </v-toolbar>
              <v-card-text>
                <iframe
                  width="560"
                  height="315"
                  src="https://www.youtube.com/embed/tEcGqbpGVto"
                  frameborder="0"
                  allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                  allowfullscreen
                />
              </v-card-text>
            </v-card>
          </v-dialog>
        </v-carousel>
      </v-flex>
    </template>
    <v-container
      fluid
      grid-list-lg
    >
      <v-layout
        v-if="geo"
        align-center
        justify-center
        row
      >
        <v-flex
          lg10
          mt-3
        >
          <p
            :class="$vuetify.breakpoint.mdAndUp
              ? 'title-jobs' : 'title-jobs-responsive'"
          >
            Trabajos destacados
          </p>
        </v-flex>
      </v-layout>
      <v-layout
        v-if="geo"
        align-center
        justify-center
        row
        wrap
      >
        <v-flex
          v-if="offers.length === 0"
          xs12
        >
          <NoResults />
        </v-flex>
        <v-flex
          v-for="offer in offers"
          v-else
          :key="offer.uuid"
          md12
          lg10
        >
          <PublicOfferCardV2
            :offer="offer"
            :show-perks="true"
            :disable-favourite="true"
            @setFavourite="isFavourite => offer.is_favourite = isFavourite"
          />
        </v-flex>
        <v-flex
          md12
          lg10
        >
          <v-btn
            :to="{name: 'candidate:offers'}"
            block
            color="primary"
          >
            Ver más ofertas
          </v-btn>
        </v-flex>
      </v-layout>
    </v-container>
    <!-- Tips -->
    <TipContainer v-if="false" />
    <!-- /Tips -->
    <Podcast />

    <v-layout
      align-center
      justify-center
      row
    >
      <v-flex
        lg12
        class="bg-f5"
      >
        <!-- te deseamos -->
        <v-layout
          align-center
          justify-center
        >
          <h2
            :class="$vuetify.breakpoint.mdAndUp ? 'footer-title' : 'footer-title-responsive'"
          >
            ¡Te deseamos mucho éxito en tu búsqueda laboral!
          </h2>
        </v-layout>
        <v-layout
          align-center
          justify-center
        >
          <p
            :class="$vuetify.breakpoint.mdAndUp ? 'footer-label' : 'footer-label-responsive'"
          >
            Puedes acceder a todo nuestro contenido que te ayudará a mejorar esta experiencia.
            ¡Descúbrelo!
          </p>
        </v-layout>
        <v-layout
          align-center
          justify-center
        >
          <v-btn
            v-model="signup_modal"
            class="btn-registrate"
            color="primary"
            @click="toggleSignupModal"
          >
            Registrate
          </v-btn>
        </v-layout>
      </v-flex>
    </v-layout>
    <!-- Footer -->
    <v-flex
      xs12
    >
      <div style="">
        <Footer
          :color="colorFooter"
        />
      </div>
    </v-flex>
    <!-- /Footer -->
  </div>
</template>

<script>
import axios from 'axios';
import CandidateSignup from '@/components/forms/CandidateSignup.vue';
import cutString from '@/utils/text';
// import getParameterByName from '@/utils/urls';
import JWTConfigMixin from '@/components/mixins/JWTConfigMixin.vue';
import RSSFeedMixin from '@/components/mixins/RSSFeedMixin.vue';
import GeoComputedMixin from '@/components/mixins/geo/GeoComputedMixin.vue';
import PublicOfferCardV2 from '@/components/offers/PublicOfferCardV2.vue';
import Search from '@/components/offers/Search.vue';
import { parseJwt, JwtIsExpired } from '@/utils/jwt';
import Footer from '@/views/Footer.vue';
import TipContainer from '@/components/landing/TipContainer.vue';
import Podcast from '@/components/landing/Podcast.vue';
import NoResults from '@/components/offers/NoResults.vue';

export default {
  name: 'Landing',
  components: {
    CandidateSignup,
    PublicOfferCardV2,
    Footer,
    Search,
    TipContainer,
    Podcast,
    NoResults,
  },
  mixins: [
    JWTConfigMixin,
    RSSFeedMixin,
    GeoComputedMixin,
  ],
  data() {
    return {
      signup_modal: false,
      youtube_modal: false,
      search_modal: false,
      colorFooter: '#FAFAFA',
      username: '',
      password: '',
      banners: [],
      headers: [
        {
          text: 'Empresa',
        },
        {
          text: 'Cargo',
        },
        {
          text: 'Acciones',
        },
      ],
      offers: [],
      filters: {
        country: '',
        keyword: '',
        region: '',
      }, // TODO: ERROR, filters debe ser un arreglo, actualmente es objeto
    };
  },
  computed: {
    filters_payload() {
      const newObj = { ...this.filters };
      Object.keys(this.filters).forEach(prop => {
        if (!this.filters[prop]) {
          delete newObj[prop];
        }
      });
      return newObj;
    },
    outplacement_link() {
      if (this.geo === 'PE') {
        return 'https://mandomedio.com/peru/workshop/';
      }
      if (this.geo === 'CO') {
        return 'https://mandomedio.com/colombia/transicion-de-carrera/asesoria-laboral/';
      }
      // default to chilean (CL) link
      return 'https://mandomedio.com/outplacement-personas/';
    },
    userIsLoggedIn() {
      return !JwtIsExpired(this.$store.state.jwt);
    },
  },
  watch: {
    '$route.params.geo': {
      handler() {
        this.loadOffers(true);
      },
      deep: true,
      immediate: true,
    },
    userIsLoggedIn() {
      if (this.userIsLoggedIn) {
        if (this.$route.query.next === 'next') {
          this.$router.push({ name: 'candidate:profile:settings' });
        } else {
          this.$router.push({ name: 'candidate:offers' });
        }
      }
    },
  },
  mounted() {
    this.$nextTick(() => {
      if (this.userIsLoggedIn) {
        if (this.$route.query.next === 'next') {
          this.$router.push({ name: 'candidate:profile:settings', params: { geo: this.geo } });
        } else {
          this.$router.push({ name: 'candidate:offers', params: { geo: this.geo } });
        }
      }
      this.firstLoad();
      this.loadBanners();

      this.$ga.event('landingMounted', 'Landing Page', 'Landing Page', 1);
    });
  },
  methods: {
    parseContent(rssContent) {
      return rssContent.split('<p>')[1].split('</p>')[0];
    },
    loadBanners() {
      console.log('url', this.$route.query.next);
      console.log('boolean', this.$route.query.next === 'next');
      axios.get(this.$store.state.endpoints.banner)
        .then(response => {
          this.banners = response.data.results;
        });
    },
    searchOffers() {
      this.$router.push({
        name: 'candidate:offers',
        query: {
          region: this.filters.region,
          keyword: this.filters.keyword,
        },
      });
      this.filters.region = '';
      this.filters.keyword = '';
    },
    firstLoad() {
      this.loadOffers(true);
      this.loadRSSFeed(this.geo);
    },
    loadOffers(firstLoad) {
      if (firstLoad) {
        this.filters.country = this.geo;
      }
      this.$store.commit('setLoading');
      const url = `${this.$store.state.endpoints.offers.public.landingOffers}`;
      axios.get(url, { ...this.jwtConfig, params: { country: this.geo } })
        .then(response => {
          /* this.nextPage = response.data.next;
          this.prevPage = response.data.previous;
          this.offers = response.data.results;
          this.pages = Math.ceil(response.data.count / response.data.results.length);
          this.$store.commit('unsetLoading');
          this.page = 1; */
          console.log(response.data);
          this.offers = response.data;
          this.$store.commit('unsetLoading');
        });
    },
    cutString(text) {
      return cutString(text, 250);
    },
    finishSignup(payload) {
      this.signup_modal = false;
      this.username = payload.username;
      this.password = payload.password;
      this.authenticate();
    },
    toggleSignupModal() {
      this.signup_modal = !this.signup_modal;
    },
    authenticate() {
      this.$store.commit('setLoading');

      const payload = {
        username: this.username,
        password: this.password,
      };

      axios.post(this.$store.state.endpoints.obtainJWT, payload)
        .then(response => {
          this.$store.commit('updateToken', response.data.token);
          this.$store.commit('setAdmin', parseJwt(this.$store.state.jwt).is_admin);
          if (!this.$store.state.isAdmin) {
            axios.get(this.$store.state.endpoints.candidates.getLoginData, this.jwtConfig)
              .then(resp => {
                this.$store.commit('setLoginDetails', resp.data);
                this.$store.commit('unsetLoading');
                this.$emit('loginSuccess', '');
              });
          } else {
            this.$store.commit('setAdminName', parseJwt(this.$store.state.jwt).username);
            this.$store.commit('unsetLoading');
            this.$emit('loginSuccess', '');
          }
        })
        .catch(error => {
          this.$store.commit('unsetLoading');
          console.error(error);
        });
    },
  },
};
</script>

<style scoped>
  .link_to_offer:hover {
    cursor: pointer;
  }
  .link_to_offer {
    text-decoration: none;
  }
  .title-jobs {
    height: 42px;
    color: rgba(0,0,0,0.87);
    font-family: Lato;
    font-size: 34px;
    font-weight: 900;
    letter-spacing: 0.25px;
    line-height: 42px;
  }
  .title-jobs-responsive {
    height: 42px;
    color: rgba(0,0,0,0.87);
    font-family: Lato;
    font-size: 20px;
    font-weight: 900;
    letter-spacing: 0.15px;
    line-height: 42px;
  }
  .title-replaces-banner-phone{
    height: 59px;
    width: 200px;
    color: rgba(0,0,0,0.87);
    font-family: Lato;
    font-size: 24px;
    font-weight: 900;
    letter-spacing: 0.18px;
    line-height: 31px;
    text-align: center;
  }
  .title-replaces-banner-tablet{
    height: 59px;
    color: rgba(0,0,0,0.87);
    font-family: Lato;
    font-size: 24px;
    font-weight: 900;
    letter-spacing: 0.18px;
    line-height: 31px;
    text-align: center;
  }
  .sub-title-replaces-banner{
    height: 68px;
    width: 270px;
    color: rgba(0,0,0,0.87);
    font-family: Lato;
    font-size: 14px;
    letter-spacing: 0.13px;
    line-height: 21.6px;
    text-align: center;
  }
  .sub-title-replaces-banner-tablet{
    height: 68px;
    width: 500px;
    color: rgba(0,0,0,0.87);
    font-family: Lato;
    font-size: 14px;
    letter-spacing: 0.13px;
    line-height: 21.6px;
    text-align: center;
    margin-left: 10%;
  }
  .button-jobs {
    height: 36px;
    width: 142px;
    border-radius: 6px;
    background-color: rgba(0, 0, 0, 0);
  }
  .rectangle-2 {
    height: 104px;
    width: auto;
    opacity: 0.24;
    background-color: #D8D8D8;
  }
  .rectangle-copy-10 {
    height: 56px;
    width: 580px;
    border-radius: 47.5px;
    background-color: #FFFFFF;
    box-shadow: 0 2px 10px 0 rgba(0,0,0,0.17);
    position: absolute;
    top: 0;
    margin-top: 25px;
    opacity: 0.6;
  }
  .puesto-empresa-o-pa-copy {
    margin-top: 17px;
    margin-left: 15px;
    margin-right: 40px;
    color: rgba(174,174,174,0.87);
    font-size: 14.8px;
    letter-spacing: 0.15px;
    line-height: 28px;
  }
  .oval-copy {
    height: 44px;
    width: 44px;
    opacity: 0.23;
    border-radius: 50%;
    background-color: #30B0BF;
    position: absolute;
  }
  .buscar {
    height: 25.2px;
    width: 221.48px;
    margin-bottom: 15px;
    color: rgba(0,0,0,0.87);
    font-family: Lato;
    font-size: 21px;
    font-weight: 900;
    letter-spacing: 0.2px;
    line-height: 25.2px;
  }
  .rectangle-copy-18 {
    box-sizing: border-box;
    border: 1px solid rgba(0,0,0,0.12);
    border-radius: 6px;
    height: 55px;
  }
  .rectangle {
    border-radius: 6px;
    background-color: #E4F5F7;
  }
  .buscar-trabajo {
    height: 24px;
    width: 99px;
    color: #30B0BF;
    font-family: Lato;
    font-size: 14px;
    font-weight: bold;
    letter-spacing: 0.3px;
    line-height: 24px;
    margin-top: 15px;
    text-transform: initial;
  }
.bg-f5{
  background-color: #f5f5f5;
}
.footer-title{
  margin-top: 76px;
  height: 42px;
  color: rgba(0,0,0,0.87);
  font-family: Lato;
  font-size: 34px;
  font-weight: 900;
  letter-spacing: 0.25px;
  line-height: 42px;
}
.footer-title-responsive {
  height: 64px;
  width: 295px;
  color: rgba(0,0,0,0.87);
  font-family: Lato;
  font-size: 24px;
  font-weight: 900;
  letter-spacing: 0;
  line-height: 32px;
  text-align: center;
  margin-top: 40px;
  margin-bottom: 13px;
}
.footer-label{
  height: 78px;
  width: 750px;
  color: rgba(0,0,0,0.87);
  font-family: Lato;
  font-size: 24px;
  letter-spacing: 0;
  line-height: 32px;
  text-align: center;
  margin-top: 50px;
}
.footer-label-responsive{
  height: 68px;
  width: 260px;
  color: rgba(0,0,0,0.87);
  font-family: Lato;
  font-size: 14px;
  letter-spacing: 0.13px;
  line-height: 21.6px;
}
.btn-registrate{
  height: 42px;
  width: 125px;
}
</style>
