<template>
  <div style="">
    <v-container
      fluid
      grid-list-lg
    >
      <v-layout
        align-center
        justify-center
        row
      >
        <v-flex
          lg10
          mt-3
        >
          <p
            :class="$vuetify.breakpoint.mdAndUp
              ? 'title-podcast' : 'title-podcast-responsive'"
          >
            Podcast
          </p>
        </v-flex>
      </v-layout>
      <v-layout
        v-if="$vuetify.breakpoint.mdAndUp"
        align-center
        justify-center
        row
      >
        <v-flex
          lg8
          mt-3
          mb-4
          class="podcast-label-container justify-center text-xs-center"
          style="text-align=  center"
        >
          <p
            class="podcast-label"
            style="backgraund-color: blue;"
          >
            "Nuestros profesionales expertos te entregaran herramientas,
            tips y consejos para ayudarte a mejorar tu empleabilidad."
          </p>
        </v-flex>
      </v-layout>

      <v-layout
        align-center
        justify-center
        col
        wrap
      >
        <v-flex
          md12
          lg10
        >
          <template>
            <v-carousel
              height="100%"
              class="elevation-0"
              hide-delimiters
              light
            >
              <v-carousel-item
                v-for="(item, i) in podcast"
                :key="i"
              >
                <div
                  align="center"
                  justify="center"
                >
                  <v-layout
                    v-observe-visibility="{
                      callback: (isVisible, entry) => item.isVisible = isVisible,
                      once: true
                    }"
                    align-center
                    justify-center
                    row
                    class="podcast-layout"
                  >
                    <iframe
                      v-if="item.isVisible"
                      :src="item.url"
                      loading="lazy"
                      height="102px"
                      width="400px"
                      frameborder="0"
                      scrolling="no"
                    />
                  </v-layout>
                </div>
              </v-carousel-item>
            </v-carousel>
          </template>
          <v-layout class="layout-escuchanos">
            <v-flex class="">
              <h2 class="Escuchanos Tambien">
                Escuchanos También En
              </h2>
              <div class="">
                <v-layout
                  row
                  justify-center
                  ml-2
                  style="margin-top:18px;"
                >
                  <a
                    href="https://www.breaker.audio/mandomedio"
                    target="_blank"
                    style="text-decoration: None"
                  >
                    <v-img
                      class="podcast-icon"
                      :src="require('@/assets/podcast_breaker.png')"
                    />
                  </a>
                  <a
                    href="https://podcasts.google.com/feed/aHR0cHM6Ly9hbmNob3IuZm0vcy80OTA5ZGFjOC9wb2RjYXN0L3Jzcw=="
                    target="_blank"
                    style="text-decoration: None"
                  >
                    <v-img
                      class="podcast-icon"
                      :src="require('@/assets/podcast_google.png')"
                    />
                  </a>
                  <a
                    href="https://radiopublic.com/mandomedio-Waw0PX"
                    target="_blank"
                    style="text-decoration: None"
                  >
                    <v-img
                      class="podcast-icon"
                      :src="require('@/assets/podcast_radiopublic.png')"
                    />
                  </a>
                  <a
                    href="https://open.spotify.com/show/5zTNadWcx2aeDDRW89XLEJ"
                    target="_blank"
                    style="text-decoration: None"
                  >
                    <v-img
                      class="podcast-icon"
                      :src="require('@/assets/podcast_spotify.png')"
                    />
                  </a>
                  <a
                    href="https://twitter.com/Mandomedio_com"
                    target="_blank"
                    style="text-decoration: None"
                  >
                    <v-img
                      class="podcast-icon"
                      :src="require('@/assets/podcast_group7.png')"
                    />
                  </a>
                  <a
                    href="https://anchor.fm/mandomedio"
                    target="_blank"
                    style="text-decoration: None"
                  >
                    <v-img
                      class="podcast-icon"
                      :src="require('@/assets/podcast_unnamed.png')"
                    />
                  </a>
                </v-layout>
              </div>
            </v-flex>
          </v-layout>
        </v-flex>
      </v-layout>
    </v-container>
  </div>
</template>

<script>
export default {
  name: 'Podcast',
  props: {
    value: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      currentItem: null,
      podcast: [
        {
          title: 'Negociar sueldo',
          url: 'https://anchor.fm/mandomedio/embed/episodes/Sabes-cmo-negociar-tu-sueldo---Mandomedio-e17ddtd',
          isVisible: false,
        },
        {
          title: 'Salida laboral',
          url: 'https://anchor.fm/mandomedio/embed/episodes/Salida-Laboral--Mandomedio-e16vlnt/a-a6foqnr',
          isVisible: false,
        },
        {
          title: 'Planificacion efectiva',
          url: 'https://anchor.fm/mandomedio/embed/episodes/Cmo-planificarnos-para-ser-efectivos-en-el-teletrabajo---Mandomedio-e165rt0/a-a6co8r5',
          isVisible: false,
        },
        {
          title: 'Manejar estres',
          url: 'https://anchor.fm/mandomedio/embed/episodes/Cmo-manejar-el-estrs-en-el-teletrabajo---Mandomedio-e15mhet/a-a6ag0r8',
          isVisible: false,
        },
        {
          title: 'Cambios laborales',
          url: 'https://anchor.fm/mandomedio/embed/episodes/Cambios-laborales-y-la-importancia-de-las-referencias-laborales-en-los-procesos-de-seleccin--Mandomedio-e146j3a/a-a63k8gp',
          isVisible: false,
        },
        {
          title: 'Auto conocimiento',
          url: 'https://anchor.fm/mandomedio/embed/episodes/La-importancia-del-autoconocimiento-en-mi-desarrollo-profesional--Mandomedio-e146apm/a-a63jdjr',
          isVisible: false,
        },
        {
          url: 'https://anchor.fm/mandomedio/embed/episodes/Cmo-desarrollar-nuestra-red-de-contactos---Mandomedio-e12joch/a-a5rogss',
          title: 'Red de contactos',
          isVisible: false,
        },
        {
          title: 'Como Afrontar una entrevista por competencias',
          url: 'https://anchor.fm/mandomedio/embed/episodes/Qu-es-y-cmo-afrontar-una-entrevista-por-competencias---Mandomedio-e12jo2c/a-a5rof9p',
          isVisible: false,
        },
        {
          title: 'Que busca un head hunter',
          url: 'https://anchor.fm/mandomedio/embed/episodes/Qu-busca-un-headhunter---Mandomedio-e11tv81/a-a5o40t9',
          isVisible: false,
        },
        {
          title: 'Busqueda activa en linkedin',
          url: 'https://anchor.fm/mandomedio/embed/episodes/Bsqueda-activa-en-Linkedin--Mandomedio-eusrv3/a-a589p4m',
          isVisible: false,
        },
        {
          title: 'Como conseguir una entrevista de trabajo',
          url: 'https://anchor.fm/mandomedio/embed/episodes/Cmo-conseguir-una-entrevista-de-trabajo--Mandomedio-eusrkf/a-a589o0m',
          isVisible: false,
        },
        {
          title: 'Como me presento en el CV',
          url: 'https://anchor.fm/mandomedio/embed/episodes/Cmo-me-presento-en-el-papel-CV--Mandomedio-eti7gr/a-a52ed3c',
          isVisible: false,
        },
        {
          title: 'Consejos practivos si te quedas sin trabajo',
          url: 'https://anchor.fm/mandomedio/embed/episodes/Consejos-prcticos-si-te-quedas-sin-trabajo--Mandomedio-etgs5r/a-a5272lb',
          isVisible: false,
        },
      ],
    };
  },
};
</script>

<style scoped>
.title-podcast {
height: 42px;
color: rgba(0,0,0,0.87);
font-family: Lato;
font-size: 34px;
font-weight: 900;
letter-spacing: 0.25px;
line-height: 42px;
}
.title-podcast-responsive {
height: 42px;
color: rgba(0,0,0,0.87);
font-family: Lato;
font-size: 24px;
font-weight: 900;
letter-spacing: 0.15px;
line-height: 42px;
text-align: center;
}
.podcast-layout{
  height: 160px;
  padding-bottom: 40px;
}
h3 {
  font-size: 20px;
  text-align: center;
}
p {
  font-size: 15px;
}
.layout-escuchanos{
  text-align: center;
}
.escuchanos-tambien-responsive {
  height: 28px;
  width: 212px;
  color: rgba(0,0,0,0.87);
  font-family: Lato;
  font-size: 20px;
  font-weight: bold;
  letter-spacing: 0.19px;
  line-height: 28px;
}
.mt-36{
  margin-top: 0px;
}
.podcast-label{
  text-align: center;
  color: #EE8146;
  font-family: Lato;
  font-size: 16px;
  letter-spacing: 0.15px;
  line-height: 19px;
}
.podcast-label-responsive{
  text-align: center;
  color: #EE8146;
  font-family: Lato;
  font-size: 16px;
  letter-spacing: 0.15px;
}
.podcast-label-container{
  text-align: center;
  width: 881px;
  border-radius: 6px;
  background-color: #FEF2EB;
}
.podcast-label-container-responsive{
  text-align: center;
  height: auto;
  width: 300px;
  border-radius: 6px;
  background-color: #FEF2EB;
}
.podcast-icon{
  height: 30px;
  width: 30px;
  color: #FFFFFF;
  margin-left: 4px;
  margin-right: 4px;
}
</style>
