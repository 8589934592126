<template>
  <div style="">
    <v-container
      fluid
      grid-list-lg
    >
      <v-layout
        align-center
        justify-center
        row
      >
        <v-flex
          lg10
          mt-3
        >
          <p
            :class="$vuetify.breakpoint.mdAndUp
              ? 'title-jobs' : 'title-jobs-responsive'"
          >
            Tips y Consejos
          </p>
        </v-flex>
      </v-layout>

      <v-layout
        align-center
        justify-center
        col
        wrap
      >
        <v-flex
          md12
          lg10
        >
          <div
            v-if="$vuetify.breakpoint.mdAndUp"
            class=""
          >
            <v-item-group>
              <v-container grid-list-md>
                <v-layout>
                  <v-flex
                    v-for="(item, i) in items"
                    :key="i"
                    xs12
                    md4
                  >
                    <v-layout
                      align-center
                      justify-center
                      row
                    >
                      <v-item
                        align="center"
                        justify="center"
                      >
                        <!-- TipBox -->
                        <div class="tip-box px-4">
                          <div class="">
                            <img
                              :src="require('@/assets/' + item.imgName + '.svg')"
                              alt=""
                              class="img-icon"
                            >
                          </div>
                          <div class="">
                            <h3>{{ item.title }}</h3>
                            <p>
                              {{ item.body }}
                            </p>
                          </div>
                        </div>
                        <!-- /TipBox-->
                      </v-item>
                    </v-layout>
                  </v-flex>
                </v-layout>
              </v-container>
            </v-item-group>
          </div>
          <div
            v-else
            class="asd"
          >
            <template>
              <v-carousel
                height="100%"
                class="elevation-0"
                hide-delimiters
                light
              >
                <v-carousel-item
                  v-for="(item,i) in items"
                  :key="i"
                >
                  <div
                    class="fill-height"
                    align="center"
                    justify="center"
                  >
                    <v-layout
                      align-center
                      justify-center
                      row
                    >
                      <div class="tip-box">
                        <div class="">
                          <img
                            class="img-icon"
                            :src="require('@/assets/' + item.imgName + '.svg')"
                          >
                        </div>
                        <div class="">
                          <h3>{{ item.title }}</h3>
                          <p>
                            {{ item.body }}
                          </p>
                        </div>
                      </div>
                    </v-layout>
                  </div>
                </v-carousel-item>
              </v-carousel>
            </template>
          </div>
          <v-layout class=" layout-sabermas">
            <v-flex>
              <v-btn
                class="btn-sabermas"
                color="primary"
              >
                Saber Mas
              </v-btn>
            </v-flex>
          </v-layout>
        </v-flex>
      </v-layout>
    </v-container>
  </div>
</template>

<script>
export default {
  name: 'Tips',
  props: {
    value: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      items: [
        {
          title: 'Entrevista de Trabajo',
          body: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Pellentesque ante dolor, faucibus ultricies diam eu, ultrices vulputate turpis.',
          imgName: 'tipicon-entrevista',
        },
        {
          title: 'Curriculum',
          body: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Pellentesque ante dolor, faucibus ultricies diam eu, ultrices vulputate turpis.',
          imgName: 'tipicon-curriculum',
        },
        {
          title: 'Finanzas',
          body: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Pellentesque ante dolor, faucibus ultricies diam eu, ultrices vulputate turpis.',
          imgName: 'tipicon-finanzas',
        },

      ],
    };
  },
};
</script>

<style scoped>
.title-jobs {
height: 42px;
color: rgba(0,0,0,0.87);
font-family: Lato;
font-size: 34px;
font-weight: 900;
letter-spacing: 0.25px;
line-height: 42px;
}
.title-jobs-responsive {
height: 42px;
color: rgba(0,0,0,0.87);
font-family: Lato;
font-size: 24px;
font-weight: 900;
letter-spacing: 0.15px;
line-height: 42px;
text-align: center;
}

.tip-box {
  max-width: 300px;
  text-align: center;
  padding-bottom: 35px;
}
h3 {
  font-size: 20px;
  text-align: center;
}
p {
  font-size: 15px;
}
.img-icon {
  margin-top: 25px;
  max-height: 100px;
  min-height: 50px;
  margin-bottom: 25px;
}
.bg-gray{
  background-color: gray;
}
.layout-sabermas{
  text-align: center;
}
.btn-sabermas{
  height: 42px;
  width: 169px;
  margin-top: 20px;
}
.px-4{
  padding-left: 2px;
  padding-right: 2px;
}
</style>
